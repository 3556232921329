<script setup lang="ts">
import VCatalogEmpty from "@magnit/unit-catalog/src/components/VCatalogEmpty.vue";
import NotFoundIcon from "~/assets/svg/not-found.svg";

const emit = defineEmits<{
  "click:primary": [];
  "click:secondary": [];
}>();
</script>

<template>
  <VCatalogEmpty
    :primary-button="{
      title: 'Перейти в каталог магазина',
      url: Routes.Categories,
    }"
    :icon="NotFoundIcon"
    description="Посмотрите другие товары"
    @click:primary="emit('click:primary')"
    @click:secondary="emit('click:secondary')"
  />
</template>
